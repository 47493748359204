
<template>
<div class="account-pages my-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5 col-8">
                <div class="text-center">
                    <img src="@assets/images/LogoLight.png" alt class="img-fluid" />
                    <h3 class="m-10 mb-10">Please wait while we log out</h3>
                </div>
            </div>
            <div class="col-12 text-center">
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    created() {
        this.cleanUpBeforeLogin();
        this.$store.dispatch("setIsProgressing", true);
        this.$store.dispatch("logout");
    },
    methods: {
        cleanUpBeforeLogin() {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
            for (var obj in sessionStorage) {
                sessionStorage.removeItem(obj);
            }
        },
    }
}
</script>